<template>
    <div class="content-box">
        <div class="content-header">
          <el-select class="input" v-model="queryForm.isEnable" clearable placeholder="启禁状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
            <el-input
                v-model="queryForm.name"
                maxlength="50"
                class="input"
                clearable
                placeholder="请输入品牌方名称"
            ></el-input>
            <el-input
                v-model="queryForm.storeName"
                maxlength="50"
                class="input"
                clearable
                placeholder="请输入门店名称"
            ></el-input>
            <el-input
                v-model="queryForm.phone"
                maxlength="50"
                class="input"
                clearable
                placeholder="请输入联系方式"
            ></el-input>
            <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
            <el-button type="plain" class="cotent-btn-big" icon="el-icon-plus" @click="handleEdit">新增品牌方</el-button>
        </div>
        <div class="table-list">
            <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
                v-loading="loading">
                <el-table-column prop="name" align="center" width="" label="品牌方名称">
                  <template #default="{row}">
                    <span @click="toStoreList(row)" class="a-span">{{row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="id" align="center" width="" label="关联门店数">
                    <template #default="{ row }">
                        <el-button @click="viewStores(row)" type="text">{{row.stores?.length}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="contact" align="center" width="" label="负责人" />
                <el-table-column prop="phone" align="center" width="" label="联系方式" />
                <el-table-column prop="createdBy" align="center" width="" label="创建人" />
                <el-table-column prop="createdTime" align="center" width="" label="创建时间" />
                <el-table-column prop="autoRegisterUser" align="center" width="" label="自动注册会员">
                  <template #default="{row}">
                    {{row.autoRegisterUser ? "是" : "否"}}
                  </template>
                </el-table-column>
                <el-table-column prop="defaultImgStatus" align="center" width="" label="默认用户头像">
                  <template #default="{row}">
                    {{row.defaultImgStatus ? "启用" : "禁用"}}
                  </template>
                </el-table-column>
                <el-table-column prop="configStatus" align="center" width="" label="配置状态">
                    <template #default="{ row }">
                        {{row.configStatus ? "已配置" : "未配置"}}
                    </template>
                </el-table-column>
                <el-table-column prop="id" align="center" width="" label="操作">
                    <template #default="{row}">
                        <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                        <el-button :class="{ 
                            'disable-btn': row.status == brandStatusEnum?.Enable,
                            'enable-btn': row.status == brandStatusEnum?.Disable,
                            }"
                            type="text" @click="handleUpdateStatus(row)">{{ row.status == brandStatusEnum?.Enable ? "启用" : "禁用"}}</el-button>
                        <el-button type="text" @click="goMerchantPrdCategory(row)">产品分类</el-button>
                        <el-button type="text" @click="goMerchantDb(row)">处方库</el-button>
                        <el-button type="text" @click="goMerchantLayout(row)">app排版</el-button>
                        <el-button type="text" @click="settingMiniConfig(row)">小程序</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
        </div>
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="490px" :before-close="dialogClose">
            <div class="edit-box">
            <el-form label-position="left" label-suffix=":" label-width="115px" :model="formData" :rules="formRules" ref="validateForm">
                <el-form-item label="品牌方名称" prop="name">
                <el-input v-model="formData.name" class="dialogInput" placeholder="请输入品牌方名称" maxlength="32">
                </el-input>
                </el-form-item>
                <el-form-item label="关联门店" prop="stores">
                <el-select
                    clearable
                    :value="undefined"
                    @change="($event) => setCurUser($event, 'storeInfo')"
                    class="dialogInput"
                    remote
                    :remote-method="getStoreList"
                    @focus="getStoreList"
                    @blur="validateField"
                    filterable
                    placeholder="请选择关联门店"
                    :loading="storeSelectLoading"
                >
                    <el-option
                    v-for="item in allStoreList"
                    :key="item.id"
                    :label="item.storeName"
                    :value="item"
                    >
                    <span>{{item.storeName}}</span>
                    </el-option>
                </el-select>
                <el-tag
                    class="tag"
                    :key="tag.id"
                    v-for="tag in storeInfo"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'storeInfo')"
                >
                    {{ tag.storeName }}
                </el-tag>
                </el-form-item>
                <el-form-item label="负责人" prop="contact">
                <el-input v-model="formData.contact" class="dialogInput"
                    placeholder="请输入负责人" maxlength="32"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="phone">
                <el-input v-model="formData.phone" class="dialogInput"
                    placeholder="请输入联系方式" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="自动注册会员" prop="autoRegisterUser">
                  <el-switch
                    v-model="formData.autoRegisterUser"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="使用养身帮产品" prop="allowUseQrcode">
                  <el-switch
                    v-model="formData.allowUseQrcode"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="用户默认头像" prop="defaultImgStatus">
                  <el-switch
                    v-model="formData.defaultImgStatus"
                    active-color="#13ce66"
                  ></el-switch>
                  <p class="upload-img-tip">启用默认头像后关联门店的中医大脑上用户头像展示默认头像</p>
                </el-form-item>
                <el-form-item label="默认头像" prop="defaultImgUrl">
                  <UploadImg :fileList.sync="formData.defaultImgUrl" :limit="1" :size="10" />
                </el-form-item>
                <el-form-item label="品牌方key" prop="apiAppKey">
                <el-input v-model="formData.apiAppKey" class="dialogInput"
                    placeholder="请输入品牌方key"></el-input>
                </el-form-item>
                <el-form-item label="品牌方secret" prop="apiAppSecret">
                <el-input v-model="formData.apiAppSecret" class="dialogInput"
                    placeholder="请输入品牌方secret"></el-input>
                </el-form-item>
                <el-form-item label="品牌logo" prop="logoImgUrl">
                  <UploadImg :fileList.sync="formData.logoImgUrl" :limit="1" :size="4" />
                  <p class="upload-img-tip">只支持jpg/png格式</p>
                </el-form-item>
                <el-form-item label="公司名称" prop="companyName">
                  <el-input v-model="formData.companyName" class="dialogInput"
                    placeholder="请输入公司名称" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="中医大脑背景" prop="backgroundImgUrl">
                  <UploadImg :fileList.sync="formData.backgroundImgUrl" :limit="1" :size="4" />
                  <p class="upload-img-tip">只支持1080*1980的jpg/png格式</p>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                <el-input v-model="formData.remark" class="dialogInput" type="textarea"
                    resize="none"
                    placeholder="请输入备注内容" maxlength="125"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogClose">取消</el-button>
                <el-button :loading="saveLoading" type="primary" @click="dialogConfirm">提交</el-button>
            </div>
            </div>
        </el-dialog>
        <el-dialog :title="storeListInfo.title" :visible.sync="storeListInfo.visible" width="600px" :before-close="storeInfoDialogClose">
            <el-table :data="storeListInfo.data" style="width: 100%" :max-height="500" :header-cell-style="headerCellStyle" border>
                <el-table-column prop="storeId" align="center" width="" label="门店id" />
                <el-table-column prop="storeName" align="center" width="" label="门店名称" />
            </el-table>
        </el-dialog>
        <MiniSetting :dialogVisible.sync="miniSettingVisible" :brandInfo="currentBrandInfo" @syncList="getData" />
    </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getMerchantList, queryStore, saveOrUpdateMerchant, changeMerchantStatus } from "@/api/prescriptionDb";

// 工具
import { debounce, cloneDeep, uniqWith } from "lodash";

import { ServiceStatusEnum as StatusEnum } from "@/enum/service.enum";

import MiniSetting from "./component/miniSetting.vue";

import UploadImg from "@/components/uploadImg.vue";

@Component({
  components: {
    MiniSetting,
    UploadImg
  }
})
export default class MerchantList extends Mixins(loading, tablePagination, Resize) {
  brandStatusEnum = StatusEnum

  queryForm = { // 查询参数
    name: "",
    phone: "",
    storeName: "",
    isEnable: "",
  }

  statusOptions = [{ label: "已启用", value: 1 }, { label: "已禁用", value: 0 }];

  tableData = [];
  itemTotal = 0;

  dialogVisible = false;
  dialogTitle = "";
  
  formData = {
    id: "",
    name: "", // 品牌商名称
    stores: [], // 这里先放个 prop 给form 的rule使用
    contact: "", // 负责人
    phone: "",
    autoRegisterUser: false,
    allowUseQrcode: false,
    defaultImgStatus: false,
    defaultImgUrl: [],
    remark: "", // 备注
    apiAppKey: "",
    apiAppSecret: "",
    logoImgUrl: "",
    companyName: "",
    backgroundImgUrl: "",
  };

  storeListInfo = {
    visible: false,
    title: "",
    data: [],
  }

  allStoreList = [];
  storeSelectLoading = false;
  storeInfo = []; // 关联门店数据

  saveLoading = false;

  miniSettingVisible = false;
  currentBrandInfo = {};

  formRules = {
    name: [{ required: true, message: "请输入品牌方名称", trigger: "blur" }],
    // stores: [{ required: true, validator: this.validateStoreInfo, trigger: "blur" }],
  }


  mounted() {
    this.windowResize(310)
    this.getData();
  }

  // form 的validate 方法
  validateStoreInfo(rule, value, callback) {
    if (this.storeInfo?.length) return callback();
    return callback(new Error("请选择关联门店")) 
  }

  getData() {
    this.showLoading();
    getMerchantList({
      ...this.queryForm,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then(res => {
      this.hideLoading();
      res.data.list.forEach((el) => {
        el.stores?.forEach((s) => {
            s.id = s.storeId;
        })
      })
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
    }).finally(() => {
      this.hideLoading();
    });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }
  // 新增/编辑
  handleEdit(row = {}) {
    // 如果row 存在，则说明是编辑
    row = cloneDeep(row)
    const isEdit = row.id;
    this.dialogTitle = isEdit ? "编辑品牌方" : "新增品牌方";
    this.dialogVisible = true;
    if (isEdit) {
      this.$nextTick(() => {
        const { stores, autoRegisterUser, allowUseQrcode, defaultImgStatus } = row;
        Object.assign(this.formData, { ...row, autoRegisterUser: Boolean(autoRegisterUser), allowUseQrcode: Boolean(allowUseQrcode), defaultImgStatus: Boolean(defaultImgStatus) });
        this.storeInfo = stores;
      })
    }
  }

  toStoreList(row) {
    this.$router.push({ path: "/storeList", query: { merchantId: row.id } })
  }

  // 查看绑定门店详情
  viewStores(row) {
    Object.assign(this.storeListInfo, { visible: true, title: row.name, data: row.stores })
  }

  storeInfoDialogClose() {
    Object.assign(this.storeListInfo, { visible: false, title: "", data: [] })
  }

  /**
   * @description 删除二次弹窗
   */
   handleUpdateStatus(row) {
    const { status, name }  = row;
    this.$confirm(`是否${status == StatusEnum?.Enable ? "启用" : "禁用"}品牌方[${name}]`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.doUpdateStatus(row);
    })
  }

  // 删除操作
  doUpdateStatus(row) {
    const params = { id: row.id };
    if (row.status == StatusEnum?.Enable) {
      params.status = StatusEnum?.Disable;
    } else {
      params.status = StatusEnum?.Enable;
    }
    changeMerchantStatus(params).then(() => {
      this.getData();
    })
  }

  getStoreRemote = debounce(function (str) {
      this.storeSelectLoading = true;
      queryStore({ storeId: str, storeName: str,pageNum: 1,pageSize: 500}).then((res) => {
        this.storeSelectLoading = false;
        this.allStoreList = res.data;
      }).catch((err) => {});
  })


  getStoreList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.getStoreRemote(str)
  }

  setCurUser(cur, key) {
    this[key].push({...cur, userId: cur.id});
    const comparator = (obj1, obj2) => obj1.id === obj2.id;
    this[key] = uniqWith(this[key], comparator)
  }

  handleClose(cur, key) {
    this[key] = this[key].filter((item) => {
      return item.id != cur.id;
    });
  }

  dialogClose() {
    // 先把这两个列表清除掉，再执行 resetFields，因为这两兄弟被watch 了
    this.storeInfo = []; // 关联门店数据
    this.userInfo = [];// 负责人手机数据

    this.$nextTick(() => {
      this.$refs['validateForm'].resetFields();
      this.formData.id = "";
      this.dialogVisible = false;
      this.saveLoading = false;
    })
  }
  dialogConfirm() {
    if(this.formData.defaultImgStatus && this.formData.defaultImgUrl.length == 0) {
      this.$message.error("请上传默认头像");
      return;
    }
    this.$refs["validateForm"].validate().then(() => {
      const params = { 
        ...this.formData,
        stores: cloneDeep(this.storeInfo)?.map((row) => ({ storeId:row.id, storeName: row.storeName })),
        autoRegisterUser: Number(this.formData.autoRegisterUser),
        allowUseQrcode: Number(this.formData.allowUseQrcode),
        defaultImgStatus: Number(this.formData.defaultImgStatus),
        defaultImgUrl: this.formData.defaultImgUrl[0]?.url|| "",
        logoImgUrl: this.formData.logoImgUrl[0]?.url || "",
        backgroundImgUrl: this.formData.backgroundImgUrl[0]?.url || "",
      }
      this.saveLoading = true;
      saveOrUpdateMerchant(params).then(() => {
        this.dialogClose();
        this.resetPages();
        this.getData();
      }).finally(() => {
        this.saveLoading = false;
      })
    });

  }
  getName(target, id, keyId, keyName) {
    let name = ''
    target.forEach(item => {
      if (item[keyId] == id) {
        name = item[keyName]
      }
    })
    return name
  }

  settingMiniConfig(row) {
    this.miniSettingVisible = true;
    this.currentBrandInfo = cloneDeep(row);
  }

  goMerchantDb(row) {
    this.$router.push({
       path: "/merchantDb",
       query: { merchantId: row.id, brandName: row.name }
    })
  }

  goMerchantLayout(row) {
    this.$router.push({
       path: "/merchantAppCompose",
       query: { merchantId: row.id, brandName: row.name }
    })
  }

  goMerchantPrdCategory(row) {
    this.$router.push({
       path: "/merchantProductCategory",
       query: { merchantId: row.id, brandName: row.name }
    })
  }

  @Watch('storeInfo')
  validateField(val, oldVal) {
    this.$refs["validateForm"].validateField(["storeInfo"])
  }

}
</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}

.dialogInput {
  width: 300px;
  height: 40px;
  border-radius: 4px;
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.tag {
  margin-right: 10px;
  height: auto;
  max-width: 100%;
  line-height: 20px;
  padding: 6px 10px;
  white-space: wrap;
  // span{
  //   white-space: normal;
  // }
}
.disable-btn {
  color: #30a16b;
}
.enable-btn {
  color: #ff6700;
}

</style>
